import { useTranslation } from "react-i18next";
import challengeStyles from "../../common/css/Challenge.module.css";

const ChallengeTeamRankingContent = ({ rankInfo }) => {
    const {t} = useTranslation();
    return (
        <tr>
            <td data-th={t("challenge.menu.chart.rank")}>
                <div>{rankInfo.ranking}</div>
            </td>
        <td data-th={t("challenge.menu.chart.team")} className={challengeStyles.ta_left}>
                <div>{rankInfo.name}</div>
            </td>
            <td data-th={t("challenge.menu.chart.people")} className={challengeStyles.ta_left}>
                <div>
                    {rankInfo.memberNames.map((memberName, idx) => (
                        rankInfo.memberNames.length -1 !== idx ?
                            `${memberName}, `
                            :
                            memberName
                    ))}
                </div>
            </td>
            <td data-th={t("challenge.menu.chart.score")}>
                <div>{rankInfo.score}</div>
            </td>
            <td data-th={t("challenge.menu.chart.setCount")}>
                <div>{rankInfo.submitCnt}</div>
            </td>
            <td data-th={t("challenge.menu.chart.setDate")}>
                <div>{rankInfo.finalDate}</div>
            </td>
        </tr>
    );
};

export default ChallengeTeamRankingContent;